.calendly-embed-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #150E32;
    color: #fff;
    padding: 4px 8px;
    border: 0;
    font-size: 18px;
    margin-left: 10px;
    border-radius: 15px;
    text-decoration: none;
    -webkit-box-shadow: 0 -2px 0 rgba(0,0,0,0.15) inset;
    -moz-box-shadow: 0 -2px 0 rgba(0,0,0,0.15) inset;
    box-shadow: 0 -2px 0 rgba(0,0,0,0.15) inset;
    white-space: nowrap;
    min-width: 200px; /* Minimum width to prevent wrapping */
  }

  /* Responsive styles for smaller screens */
  @media (max-width: 480px) {
    .calendly-embed-button {
      font-size: 16px; /* Smaller font size for small screens */
      padding: 4px 6px; /* Smaller padding for small screens */
    }
  }
